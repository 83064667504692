export default {
  _id: {
    type: String,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  description: {
    label: 'Descripción',
    type: String
  },
  type: {
    label: 'Tipo',
    type: String,
    options: [{
      id: 'PMI-RTSP',
      name: 'CAMARA RTSP',
    }, {
      id: 'SENSOR-RTSP',
      name: 'BOTON DE PANICO RTSP',
    }, {
      id: 'DEVICE-RTSP',
      name: 'UNIDAD RTSP',
    }, {
      id: 'DEVICE-FLV',
      name: 'UNIDAD FLV',
    }]
  },
  baseUrl: {
    label: 'URL Base',
    type: String
  },
  metadata: {
    label: 'Metadatos',
    type: Array,
    title: (item) => {
      return item.label + ' / ' + item.name + ' / ' + item.type;
    },
    optional: true,
  },
  'metadata.$': {
    type: {
      name: {
        label: 'Nombre',
        type: String,
      },
      type: {
        label: 'Nombre',
        type: String,
        options: [{
          id: 'String',
          name: 'String',
        }, {
          id: 'Number',
          name: 'Number',
        }, {
          id: 'Boolean',
          name: 'Boolean',
        }]
      },
      label: {
        label: 'Label',
        type: String,
      },
    },
  },
};
